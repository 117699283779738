import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ChatBubble from './chatBubble';
import '../styles/ChatScreen.scss';
import RotatingImage from './rotatingImage';

const ChatScreen = () => {
  const [messageSets, setMessageSets] = useState([
    ["Hello there!", "I'm keen to learn more about the tech stack you used in the A/B testing project you've mentioned on your resume?"],
    ["Your experience mentions building an API server using Fast API", "Why did you choose that over using Django?"],
    ["The improvements you suggested still don't solve the compile time error. Let's focus on this part: ", 
     <SyntaxHighlighter language="python" style={oneLight} customStyle={{ fontSize: '14px' }}>
{`logger.info(f"{request.doc_id}")
ref = db.reference(f'interviews/{request.doc_id}')
data = ref.get()`}
     </SyntaxHighlighter>
    ],
    ["Now we usually get anywhere between 6000 to 15,000 requests per min.", "Let's talk about how we can optimize our module.."],
    ["Yes this role does come with a generous WFH policy.", "Would you like to know the details?"]
  ]);
  
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [visibleMessages, setVisibleMessages] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get('name');
    if (name) {
      setMessageSets(prevSets => [
        [`Hello, ${name}!`, ...prevSets[0].slice(1)],
        ...prevSets.slice(1)
      ]);
    }
  }, []);

  useEffect(() => {
    const currentSet = messageSets[currentSetIndex];

    if (currentMessageIndex < currentSet.length) {
      const timer = setTimeout(() => {
        setVisibleMessages(prev => [...prev, currentSet[currentMessageIndex]]);
        setCurrentMessageIndex(prevIndex => prevIndex + 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      const clearTimer = setTimeout(() => {
        setVisibleMessages([]);
        setCurrentSetIndex((prevIndex) => (prevIndex + 1) % messageSets.length);
        setCurrentMessageIndex(0);
      }, 5500); // Time to wait before clearing and moving to next set

      return () => clearTimeout(clearTimer);
    }
  }, [currentSetIndex, currentMessageIndex, messageSets]);

  return (
    <div className="w-full relative">
      <RotatingImage />
      <div className="chat-screen">
        {visibleMessages.map((message, index) => (
          <ChatBubble key={index} message={message} />
        ))}
      </div>
    </div>
  );
};

export default ChatScreen;