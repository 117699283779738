import React, { useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import * as EmailValidator from 'email-validator';

const ErrorDialog = ({ message }) => {
  const [isVisible, setIsVisible] = useState(!!message);

  useEffect(() => {
    setIsVisible(!!message);
    
    if (message) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded shadow-lg">
      {message}
    </div>
  );
};

const GetAccess = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const validateEmail = (email) => {
    const personalDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
    const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'mil', 'io', 'co', 'ai', 'app'];

    if (!EmailValidator.validate(email)) {
      return 'Please enter a valid email address';
    }

    const [localPart, domain] = email.split('@');
    
    if (localPart.length < 5) {
      return 'Please enter a valid work email address.';
    }

    const tld = domain.split('.').pop().toLowerCase();
    if (!commonTLDs.includes(tld)) {
      return 'Please enter a valid work email address.';
    }
    
    if (personalDomains.includes(domain.toLowerCase())) {
      return 'Please enter a valid work email address.';
    }

    const disposableDomains = ['mailinator.com', 'temp-mail.org', 'guerrillamail.com'];
    if (disposableDomains.some(d => domain.toLowerCase().includes(d))) {
      return 'Please enter a valid work email address.';
    }

    if (domain.split('.')[0].length <= 3) {
      return 'Please enter a valid work email address.';
    }

    return '';
  };

  const handleSubmit = () => {
    const validationError = validateEmail(email);
    if (validationError) {
      setError(validationError);
    } else {
      mixpanel.track('Get Access', {
        'Email': email
      });
      setSubmitted(true);
      setError('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  if (submitted) {
    return (
      <div className="pl-0 home-subscription !w-4/5">
        <p>Your response has been recorded. Expect an email from us with further details!🎉</p>
      </div>
    );
  }

  return (
    <>
      <div className="pl-0 home-subscription !w-4/5">
        <input
          type="email"
          placeholder="Share your email"
          autoFocus
          autoComplete="email"
          minLength="8"
          maxLength="50"
          className="home-textinput input sm:!pl-0"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button 
          className="rounded-full px-4 lg:px-6 py-2 lg:py-3 bg-primary text-white !text-sm md:!text-lg lg:ml-4 shadow-md"
          onClick={handleSubmit}
        >
          Get Access
        </button>
      </div>

      <ErrorDialog message={error} />
    </>
  );
};

export default GetAccess;