import React, { useState, useEffect } from 'react';
import * as EmailValidator from 'email-validator';
import mixpanel from 'mixpanel-browser';

const ErrorDialog = ({ message }) => {
  const [isVisible, setIsVisible] = useState(!!message);

  useEffect(() => {
    setIsVisible(!!message);
    
    if (message) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded shadow-lg">
      {message}
    </div>
  );
};

const NewsletterSubscription = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  const validateEmail = (email) => {
    const personalDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
    const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'mil', 'io', 'co', 'ai', 'app'];

    if (!EmailValidator.validate(email)) {
      return 'Please enter a valid email address.';
    }

    const [localPart, domain] = email.split('@');
    
    if (localPart.length < 5) {
      return 'Please enter a valid email address.';
    }

    const tld = domain.split('.').pop().toLowerCase();
    if (!commonTLDs.includes(tld)) {
      return 'Please enter a valid email address.';
    }

    const disposableDomains = ['mailinator.com', 'temp-mail.org', 'guerrillamail.com'];
    if (disposableDomains.some(d => domain.toLowerCase().includes(d))) {
      return 'Please enter a valid email address.';
    }

    if (domain.split('.')[0].length <= 3) {
      return 'Please enter a valid email address.';
    }

    return '';
  };

  const handleSubmit = () => {
    const validationError = validateEmail(email);
    if (validationError) {
      setError(validationError);
    } else {
      mixpanel.track('Event Subscription', { 'Email': email });
      setSubmitted(true);
      setError('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  if (submitted) {
    return (
      <div className="home-actions1 !items-center">
        <span className="home-content1 thq-body-small text-center">
          Thank you for subscribing to our newsletter! 🎉
        </span>
      </div>
    );
  }

  return (
    <div className="home-actions1 !items-center">
      <span className="home-content1 thq-body-small text-center">
        Stay updated on new features and product releases by subscribing to our newsletter.
      </span>
      <div className="home-actions2">
        <div className="home-form !flex !flex-col md:flex-row">
          <div className="home-container26">
            <input
              type="email"
              placeholder="Enter your email"
              autoComplete="email"
              minLength="8"
              maxLength="50"
              className="home-text-input thq-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <button className="home-button thq-button-outline" onClick={handleSubmit}>
            <span className="home-action12 thq-body-small">Subscribe</span>
          </button>
        </div>
      </div>
      <ErrorDialog message={error} />
    </div>
  );
};

export default NewsletterSubscription;