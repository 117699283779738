import React from 'react';
import '../styles/RotatingImage.scss';

const RotatingImage = ({ imageUrl }) => {
    return (
      <div className="relative w-80 h-80 lg:w-96 lg:h-96 2xl:w-[425px] 2xl:h-[425px]">
        <div className="rotating-image-container">
          <img src='/images/wireframe-bg.webp' alt="Rotating image" className="rotating-image" />
        </div>
        <div className="overlay-container"></div>
        <div className="top-overlay-container"></div>
      </div>
    );
  };
  
  export default RotatingImage;