import React, { useState, useEffect } from 'react';

const TypewriterText = ({ content }) => {
  const [displayContent, setDisplayContent] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let text;
    if (typeof content === 'string') {
      text = content;
    } else if (React.isValidElement(content)) {
      text = content.props.children;
    } else {
      return; // Exit if content is neither string nor valid React element
    }

    const timer = setInterval(() => {
      if (index < text.length) {
        setDisplayContent(text.slice(0, index + 1));
        setIndex(prevIndex => prevIndex + 1);
      } else {
        clearInterval(timer);
      }
    }, 30); // You can adjust the speed here

    return () => clearInterval(timer);
  }, [content, index]);

  if (typeof content === 'string') {
    return <span>{displayContent}</span>;
  } else if (React.isValidElement(content)) {
    return React.cloneElement(content, { children: displayContent });
  }

  return null;
};

export default TypewriterText;