import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import mixpanel from "mixpanel-browser";

import './style.css'
import Home from './views/home'
import NotFound from './views/not-found'

const App = () => {
  mixpanel.init("1fb9e8373d29824c2e1d4504542418ca", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });

  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
