import React, { useState, useEffect } from 'react';
import TypewriterText from './typewriterText';

const ChatBubble = ({ message }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const bubbleStyle = {
    maxWidth: typeof message === 'string' 
      ? `${Math.min(message.length * 10, 300)}px`
      : '100%' // For code blocks, allow full width
  };

  return (
    <div className={`chat-bubble text-sm ${isVisible ? 'visible' : ''}`} style={bubbleStyle}>
      <TypewriterText content={message} />
    </div>
  );
};

export default ChatBubble;