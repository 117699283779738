import React from 'react'

import { Helmet } from 'react-helmet'
import ChatScreen from '../components/chatScreen'

import './home.css'
import GetAccess from '../components/get-access'
import NewsletterSubscription from '../components/newsletter-sub'

const Home = (props) => {
  return (
    <div className="home-container10">
      <Helmet>
        <title>Tuezday AI</title>
        <meta property="og:title" content="Tuezday AI" />
      </Helmet>
      <header className="home-container11">
        <header data-thq="thq-navbar" className="home-navbar-interactive py-6">
          <div data-thq="thq-navbar-nav" className="w-full flex flex-row justify-center">
            <div className="w-full lg:w-4/5 2xl:w-2/3 flex flex-row items-center justify-center sm:justify-between">
              <span className="home-text10">tuezday<span className='text-primary'>.ai</span></span>
              <div className="home-container13 display-hidden md:display-block">
                <nav className="home-links1">
                  <a
                    href="#about"
                    className="home-link41 thq-link thq-body-small"
                  >
                    About
                  </a>
                  {/* <a
                    href="#pricing"
                    className="home-link51 thq-link thq-body-small"
                  >
                    Pricing
                  </a> */}
                </nav>
                <div className="home-buttons1">
                  <a
                    href="#hero"
                    className="px-4 py-2 thq-button-animated bg-primary text-white rounded-full shadow-lg border-none"
                  >
                    <span className="home-text11 thq-body-small">
                      Get Access
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
      </header>
      <section className="w-full flex flex-col gap-4 min-h-screen justify-center items-center pb-8 sm:pb-32">
        <div id="hero" className="flex flex-col md:flex-row justify-center lg:justify-between items-center w-full lg:w-[72%] 2xl:w-[66.67%]">
          <div className="home-content2">
            <h1 className="home-text15 pt-8 px-4 md:!px-0">
              <span className='hidden lg:inline'>Your Personal AI
              <br></br>
              Technical Interviewer
              </span>
              <span className="lg:hidden">
                Your Personal
                <br />
                AI Technical
                <br />
                Interviewer
              </span>
            </h1>
            <p className="home-text20 mt-4 sm:mt-0">
              <span className='font-light'>
                AI that conducts personalized candidate assessments and generates
                actionable insights at scale.
              </span>
              <br></br>
            </p>
            <div className="w-full md:w-fit rounded-full mt-8 mb-2 px-4">
              <span className="text-primary font-thin text-left text-xs sm:text-md 2xl:text-lg text-center w-full flex justify-center">
                Join multiple growth stage companies on this journey
              </span>
            </div>
            {/* <div className="pl-0 home-subscription !w-4/5">
              <input
                type="email"
                placeholder="Share your email"
                autoFocus="true"
                autoComplete="email"
                minlength="8"
                maxlength="50"
                className="home-textinput input sm:!pl-0"
              />
              <span className="rounded-full px-4 lg:px-6 py-2 lg:py-3 bg-primary text-white !text-sm md:!text-lg lg:ml-4 shadow-md">Get Access</span>
            </div> */}
            <GetAccess/>
          </div>
          <div className="p-4">
            <ChatScreen/>
          </div>
        </div>
      </section>
      <section className="home-features1 !pt-6 sm:!pt-32 sm:min-h-screen">
        <div className="home-container16 w-full lg:w-4/5">
          <div className="home-title1">
            <span className="home-text24">Traditional Evaluation Pipeline</span>
            <span className="home-text25 2xl:!text-2xl">
              Technical roles have fundamentally changed. But your hiring
              hasn&apos;t accounted for it.
            </span>
          </div>
          <div className="home-cards1 !px-0 !mt-6 sm:!mt-12 md:!mt-24">
            <div className="home-container17 !flex !flex-col md:!flex-row">
              <div className="home-schedule1 card shadow-black shadow-sm hover:shadow-xl flex justify-center lg:!pb-12 2xl:!pt-8 2xl:!pb-16">
                <img
                  alt="image"
                  src="/images/cons-1.webp"
                  className="home-image2 hidden md:block"
                />
                <span className="home-text26 !text-center">
                  <span className='font-extrabold 2xl:!text-2xl'>
                    Unreliable online quizzes
                  </span>
                </span>
                <span className="home-text29 !text-center !pb-2 !px-0">
                  Today&apos;s skill test providers have not only become
                  increasingly irrelevant but are also prone to plagiarism.
                </span>
              </div>
              <div className="home-schedule2 card shadow-black shadow-sm hover:shadow-xl flex justify-center lg:!pb-12 2xl:!pt-8 2xl:!pb-16">
                <img
                  alt="image"
                  src="/images/cons-2.webp"
                  className="home-image3 hidden md:block"
                />
                <span className="home-text26 !text-center">
                  <span className='font-extrabold text-center 2xl:!text-2xl'>
                    Time-consuming interviews
                  </span>
                </span>
                <span className="home-text31 text-center !pb-2 !px-0">
                  An average senior team member spends over 25+ hours
                  monthly to help close a single position.
                </span>
              </div>
              <div className="home-schedule3 card shadow-black shadow-sm hover:shadow-xl flex justify-center lg:!pb-12 2xl:!pt-8 2xl:!pb-16">
                <img
                  alt="image"
                  src="/images/cons-3.webp"
                  className="home-image4 hidden md:block"
                />
                <span className="home-text32 text-center 2xl:!text-2xl">
                  Costly outsourced solutions
                </span>
                <span className="home-text33 !text-center !pb-2 !px-0">
                  7/10 companies face dificulty in booking interviews, inconsistent results and high charges.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="home-features2 sm:min-h-screen">
        <div className="home-container18">
          <div className="home-title2 text-center">
            <span className="home-text34">Modern Evaluation Pipeline</span>
            <span className="home-text35 text-center 2xl:!text-2xl">
              Conduct hyper-customised interviews that assess real-world
              skills at scale.
            </span>
          </div>
          <div className="home-cards2 sm:!px-0">
            <div className="home-container19 !flex !flex-col md:!flex-row">
              <div className="home-schedule4 card lg:!pb-12 2xl:!pt-8 2xl:!pb-16">
                <img
                  alt="image"
                  src="/images/pros-1.webp"
                  className="home-image5 hidden md:block"
                />
                <span className="home-text36 !text-center 2xl:!text-2xl">
                  In-depth probing interviews
                </span>
                <span className="home-text37 !text-center !pb-2 sm:!px-0">
                  Our AI uses conversational mediums that goes into incredible
                  depth bringing out the best in each candidate.
                </span>
              </div>
              <div className="home-schedule5 card lg:!pb-12 2xl:!pt-8 2xl:!pb-16">
                <img
                  alt="image"
                  src="/images/pros-2.webp"
                  className="home-image6 hidden md:block"
                />
                <span className="home-text38 !text-center 2xl:!text-2xl">
                  Personalised for your rubric
                </span>
                <span className="home-text39 !text-center !pb-2 sm:!px-0">
                  Tuezday selects the questions, the complexity and the feedback
                  criteria based precisely on your unique requirements.
                </span>
              </div>
              <div className="home-schedule6 card lg:!pb-12 2xl:!pt-8 2xl:!pb-16">
                <img
                  alt="image"
                  src="/images/pros-3.webp"
                  className="home-image7 hidden md:block"
                />
                <span className="home-text40 !text-center 2xl:!text-2xl">
                  Save 100s of team hours
                </span>
                <span className="home-text41 !text-center !pb-2 sm:!px-0">
                  With superior interviews and consistent objective feedback,
                  Tuezday reduces a 5 round average process to 2.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-banners  md:min-h-screen py-24">
        <div className="home-banner-manage">
          <div className="rounded-2xl flex flex-col sm:flex-row justify-center md:justify-between py-16 2xl:!py-32 !max-w-3/5 px-4 md:px-8 md:!px-4 lg:!px-8 2xl:!px-24 !bg-primary !text-primary shadow-lg hover:shadow-xl">
            <div className="home-left">
              <span className="home-text42 title">
                <span className="home-text43">Assessment</span>
                <br className="home-text44"></br>
                <span className="home-text45">report that speaks volume</span>
                <br></br>
              </span>
              <span className="home-text47 !text-white md:!self-start !w-full mt-8">
                <span className='!text-lg !font-semibold 2xl:!text-2xl'>Tuezday generates assessment reports with:</span>
                <br></br>
                <div className='flex flex-row gap-2 items-center 2xl:!text-xl'>
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon20"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  Timestamped interview playbacks.
                </div>
                <div className='flex flex-row gap-2 items-center 2xl:!text-xl'>
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon20"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  Section wise performance breakdown.
                </div>
                <div className='flex flex-row gap-2 items-center 2xl:!text-xl'>
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon20"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span>Expert feedback based on your preferences.</span>
                </div>
                </span>
            </div>
            <div className="home-image-container !hidden md:!flex">
              <img
                alt="pastedImage"
                src="/images/assess.webp"
                className="home-cards-image !w-96 !h-96"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section id="pricing" className="home-pricing">
        <div className="home-centered-container">
          <div className="home-heading">
            <span className="home-text61 title">Risk Free Pricing</span>
            <span className="home-text62 2xl:!text-2xl">
              We offer you a peak into the future of technical recruitment
              without upfront commitments.
            </span>
            <div className="home-selection">
              <span className="home-text63">Monthly</span>
              <span className="home-text64">Yearly</span>
            </div>
          </div>
          <div className="home-cards3">
            <div className="home-card1 !pt-8 !pb-2 hover:shadow-xl">
              <span className="home-text65">Free</span>
              <span className="home-text66 !mb-2 2xl:!text-xl">
                Conduct in-depth interviews for your first position for no
                charge.
              </span>
              <span className="home-text68 !mt-2 !mb-2">What&apos;s included</span>
              <div className="home-bullet-points1">
                <div className="home-point1">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon20"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text69 !ml-1">Unlimited question bank</span>
                </div>
                <div className="home-point2">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon22"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text70 !ml-1">
                    Custom assessment filters
                  </span>
                </div>
                <div className="home-point3">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon24"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text71 !ml-1">
                    <span>100 technical interviews</span>
                  </span>
                </div>
              </div>
              <a href="#hero" className="home-link6">
                <div className="home-get-started1 template-button !mt-6 !shadow-lg">
                  <span className="home-text67">Start for free</span>
                </div>
              </a>
            </div>
            <div className="home-card2 !pt-8 !pb-2 hover:shadow-xl">
              <span className="home-text75">Subscription</span>
              <span className="home-text76 !mb-2 2xl:!text-xl">
                Best-in-class technical recruiter to manage your
                entire pipeline.
              </span>
              <span className="home-text80 !mt-2 !mb-2 2xl:!text-xl">What&apos;s included</span>
              <div className="home-bullet-points2">
                <div className="home-point4">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon26"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text81 !ml-1">
                    Unlimited technical interviews
                  </span>
                </div>
                <div className="home-point5">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon28"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text82 !ml-1">Personalised formatting</span>
                </div>
                <div className="home-point7">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon32"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text84 !ml-1">Dedicated support</span>
                </div>
              </div>
              <a href="#get-access" className="home-link7">
                <div className="home-get-started2 template-button !mt-6 !shadow-lg">
                  <span className="home-text77">
                    <span>Speak with us</span>
                    <br></br>
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section> */}
      <section id="get-access" className="flex flex-col md:!grid md:!grid-cols-2 !gap-0">
        <div className="bg-primary pr-12">
          <div className="home-container21 !h-full !pl-4 !pr-0 md:!items-end">
            <img
              alt="image"
              src="/images/icon-download.webp"
              className="home-image8"
            />
            <h2 className="home-text85">Get Access</h2>
            <p className="text-white mt-2 !px-0 !w-full md:!text-right">
              Uploading your job kit and share the link with candidates.
            </p>
            <div className="home-container22">
              <a href="#hero" className="home-link8 button !rounded-full drop-shadow-md hover:drop-shadow-xl">
                <span>
                  <span>Get Access</span>
                  <br></br>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="home-container23 !pl-4 !h-full !w-full">
          <div className="home-container24 !h-full !w-full !px-0">
            <img
              alt="image"
              src="/images/icon-call.webp"
              className="home-image9"
            />
            <h2 className="home-text90 !px-0 !w-full">Request Demo</h2>
            <p className="home-text91">
              <span className="text-white mt-2">
                A &apos;Done For You&apos;
                solution tailored for your job!
              </span>
              <br></br>
            </p>
            <div className="home-container25">
              <a
                href="https://cal.com/tuezday/chat"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link9 button !rounded-full drop-shadow-md hover:drop-shadow-xl"
              >
                <span>
                  <span>Request Demo</span>
                  <br></br>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer className="home-footer8 thq-section-padding">
        <div className="home-max-width thq-section-max-width">
          <div className="home-content4">
            <div className="home-column">
              <div className="home-logo2">
                <span className="home-text97">tuezday<span className='text-primary'>.ai</span></span>
              </div>
            </div>
            <NewsletterSubscription/>
          </div>
          <div className="home-credits">
            <div className="home-divider thq-divider-horizontal"></div>
            <div className="home-row">
              <div className="home-footer-links">
                <span className="home-link11 thq-body-small">
                  Privacy Policy
                </span>
                <span className="home-link12 thq-body-small">
                  Terms of Service
                </span>
                <span className="home-link13 thq-body-small">
                  Cookies Policy
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home
